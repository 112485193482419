/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

exports.onRouteUpdate = args => {
  const { location, prevLocation } = args
  // If click to new link then reset to top
  if (
    typeof window !== "undefined" &&
    location?.pathname !== prevLocation?.pathname
  ) {
    window.scrollTo(0, 0)
  }
}
